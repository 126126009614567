import type model from './model';
import { SECTION_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { PopulatedSection } from '../../types/menusTypes';
import type { I$W, TFunction } from '@wix/yoshi-flow-editor';
import { state } from '../../states/RootState';
import type { SectionState } from '../../types/businessTypes';
import { SECTION_STATES } from '../../types/businessTypes';
import type { ControllerParams } from '../../types/widgets';
import { AvailabilityStatus } from '@wix/ambassador-restaurants-menu-settings-v1-menu-ordering-settings/types';

type Bind = ControllerParams<typeof model>['$bind'];

export class SectionsController {
  constructor(private $bind: Bind, private $w: I$W, private t: TFunction) {
    state.pubsub.subscribe('onScrollToSection', this.scrollToSection);
  }

  init(sections: PopulatedSection[], menuId: string) {
    if (sections.length > 0) {
      this.switchState(SECTION_STATES.sections);
      this.$bind(SECTION_WIDGET_COMPONENT_IDS.repeaterSections, {
        data: () =>
          sections.map(({ id, ...rest }) => ({
            _id: id,
            ...rest,
          })),
        item: (itemData: PopulatedSection & { _id: string }, bindItem: Bind) => {
          bindItem(SECTION_WIDGET_COMPONENT_IDS.sectionTitle, {
            text: () => itemData.name || '',
            collapsed: () => !itemData.name,
          });

          bindItem(SECTION_WIDGET_COMPONENT_IDS.sectionDescription, {
            text: () => itemData.description || '',
            collapsed: () => !itemData.description,
          });

          bindItem(SECTION_WIDGET_COMPONENT_IDS.dishesContainer, {
            data: () => ({
              items: itemData.items,
              sectionId: itemData._id,
              menuId,
              isMenuAvailable:
                state.menusAvailabilityStatus[menuId] !== AvailabilityStatus.UNAVAILABLE,
              isTruncated: itemData.truncated,
            }),
            // @ts-expect-error
            onViewportEnter: () => {
              state.pubsub.publish('onSectionViewportEnter', {
                sectionId: itemData._id,
              });
            },
            onViewportLeave: () => {
              state.pubsub.publish('onSectionViewportLeave', {
                sectionId: itemData._id,
              });
            },
          });
        },
      });
    } else {
      this.switchState(SECTION_STATES.sectionEmptystate);
      this.$bind(SECTION_WIDGET_COMPONENT_IDS.sectionEmptyStateTitle, {
        text: () => this.t('menu_olo.emptyState.title'),
      });
      this.$bind(SECTION_WIDGET_COMPONENT_IDS.sectionEmptyStateSubtitle, {
        text: () => this.t('menu_olo.emptyState.subTitle'),
      });
    }
  }

  switchState(sectionState: SectionState) {
    const multiStateBox = this.$w(SECTION_WIDGET_COMPONENT_IDS.sectionMultiStateBox);
    multiStateBox.changeState(sectionState);
  }

  scrollToSection = ({ sectionId }: { sectionId: string }) => {
    this.$w(SECTION_WIDGET_COMPONENT_IDS.repeaterSections).forItems([sectionId], ($item: I$W) => {
      $item(SECTION_WIDGET_COMPONENT_IDS.sectionTitle).scrollTo();
    });
  };
}

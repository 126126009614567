import model from './model';
import { FulfillmentPickerController } from './FulfillmentPickerController';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { fulfillmentPickerStore } from 'root/states/FulfillmentPickerStore';
import { autorun } from 'mobx';
import type { DispatchType } from 'root/types/businessTypes';

export default model.createController(({ $w, $widget, flowAPI, $bindAll, $bind }) => {
  const t = flowAPI.translations.t as TFunction;
  const isSSR = flowAPI.environment.isSSR;
  const fulfillmentPickerController = new FulfillmentPickerController($w, $bindAll, $bind);

  let didSetOnClickHandlers = false;

  const setOnClickHandlersIfNeeded = (
    onDispatchTypeChange?: (dispatchType: DispatchType) => void
  ) => {
    if (!didSetOnClickHandlers && onDispatchTypeChange) {
      fulfillmentPickerController.initButtons();
      didSetOnClickHandlers = true;
    }
  };

  autorun(async () => {
    const {
      availableDispatchTypes,
      configuredDispatchTypes,
      isLoading,
      selectedDispatchType,
      onDispatchTypeChange,
    } = fulfillmentPickerStore;
    await fulfillmentPickerController.initFulfillmentPicker(t, configuredDispatchTypes);
    await fulfillmentPickerController.setDispatchState(
      availableDispatchTypes,
      isLoading,
      selectedDispatchType
    );
    !isLoading && setOnClickHandlersIfNeeded(onDispatchTypeChange);
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});

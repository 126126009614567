import type { PopulatedMenu } from 'root/types/menusTypes';

// this method should limit items count on menus according to second parameter
const truncateMenus = (menus: PopulatedMenu[], maxItems: number): PopulatedMenu[] => {
  let itemsLeft = maxItems;
  return menus.map((menu) => {
    return {
      ...menu,
      sections: menu.sections.map((section) => {
        const truncatedSection = { ...section, items: section.items?.slice(0, itemsLeft) };
        itemsLeft -= (section.items || []).length;
        itemsLeft = Math.max(itemsLeft, 0);
        return {
          ...truncatedSection,
          truncated: truncatedSection.items?.length !== (section.items || []).length,
        };
      }),
      size: Math.min(menu.size, maxItems),
      truncated: menu.size > maxItems,
    };
  });
};

export { truncateMenus };

import model from './model';
import { CartButtonController } from './CartButtonController';
import { state } from 'root/states/RootState';
import { BIReporterService } from 'root/services/biReporterService';
import type { CartButtonNavigationTarget } from 'root/types/cartButton';

export default model.createController(({ $bindAll, $bind, $widget, $w, flowAPI }) => {
  const { bi, environment, controllerConfig } = flowAPI;
  const { compId } = controllerConfig;
  state.biReporterService = BIReporterService({
    biLogger: bi,
    environment,
    widgetInstanceId: compId,
  });

  const cartButtonController = new CartButtonController($bind, $w, flowAPI);

  $widget.onPropsChanged(async (prevProps, nextProps) => {
    if (prevProps.navigationTarget !== nextProps.navigationTarget) {
      await cartButtonController.init(nextProps.navigationTarget as CartButtonNavigationTarget);
    }
  });
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      await cartButtonController.init($widget.props.navigationTarget as CartButtonNavigationTarget);
    },
    exports: {},
  };
});

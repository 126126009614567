import type model from './model';
import { MENU_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { PopulatedMenu } from '../../types/menusTypes';
import type { I$W, TFunction } from '@wix/yoshi-flow-editor';
import { state } from '../../states/RootState';
import type { MenuState } from '../../types/businessTypes';
import { MENU_STATES } from '../../types/businessTypes';
import type { ControllerParams } from '../../types/widgets';

type Bind = ControllerParams<typeof model>['$bind'];

export class MenusController {
  sectionsIdsMap: Record<string, string> = {};

  constructor(private $bind: Bind, private $w: I$W, private t: TFunction) {
    state.pubsub.subscribe('onScrollToMenu', this.scrollToMenu);
    state.pubsub.subscribe('onMenusDataFetchFailed', this.switchToEmptyState.bind(this));
  }

  init(menus: PopulatedMenu[]) {
    if (menus.length > 0) {
      this.switchState({ menuState: MENU_STATES.menu });

      menus.forEach((menu) => {
        const mapSections = menu.sectionIds?.reduce(
          (acc, sectionId) => ({ ...acc, [sectionId as string]: menu.id }),
          {}
        );
        this.sectionsIdsMap = { ...this.sectionsIdsMap, ...mapSections };
      });

      this.$bind(MENU_WIDGET_COMPONENT_IDS.repeaterMenus, {
        data: () =>
          menus.map(({ id, ...rest }) => ({
            _id: id,
            ...rest,
          })),
        item: (itemData: PopulatedMenu & { _id: string }, bindItem: Bind) => {
          bindItem(MENU_WIDGET_COMPONENT_IDS.menuTitle, {
            text: () => itemData.name || '',
            collapsed: () => !itemData.name,
          });

          bindItem(MENU_WIDGET_COMPONENT_IDS.menuDescription, {
            text: () => itemData.description || '',
            collapsed: () => !itemData.description,
          });

          bindItem(MENU_WIDGET_COMPONENT_IDS.menuDivider, {
            collapsed: () => !itemData.sections.length,
          });

          bindItem(MENU_WIDGET_COMPONENT_IDS.sectionContainer, {
            data: () => ({
              sections: itemData.sections,
              menuId: itemData._id,
            }),
          });
        },
      });
    } else {
      this.switchToEmptyState();
    }
  }

  switchToEmptyState({
    shouldShowErrorContent = false,
  }: { shouldShowErrorContent?: boolean } = {}) {
    this.switchState({ menuState: MENU_STATES.menuEmptystate });
    this.$bind(MENU_WIDGET_COMPONENT_IDS.menuEmptyStateTitle, {
      text: () =>
        shouldShowErrorContent
          ? this.t('menu_olo.errorState.title')
          : this.t('menu_olo.emptyState.title'),
    });
    this.$bind(MENU_WIDGET_COMPONENT_IDS.menuEmptyStateSubtitle, {
      text: () =>
        shouldShowErrorContent
          ? this.t('menu_olo.errorState.subTitle')
          : this.t('menu_olo.emptyState.subTitle'),
    });
  }

  switchState({ menuState }: { menuState: MenuState }) {
    const multiStateBox = this.$w(MENU_WIDGET_COMPONENT_IDS.menuMultiStateBox);
    multiStateBox.changeState(menuState);
  }

  scrollToMenu = ({ menuId }: { menuId: string }) => {
    this.$w(MENU_WIDGET_COMPONENT_IDS.repeaterMenus).forItems([menuId], ($item: I$W) => {
      $item(MENU_WIDGET_COMPONENT_IDS.menuTitle).scrollTo();
    });
  };
}
